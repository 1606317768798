<template>
    <div class="content">
        <div class="row">
            
            <div class="col-md-12">
                <vuestic-widget :headerText="$t('view.typestation.title')">
                    <p>{{$t('view.typestation.description')}}</p>
                    <div class="row">
                        <div class="col-md-12 pull-right">
                            <button type="button" v-on:click="newTypestation" class="btn btn-primary btn-sm">New Staff</button>
                        </div>
                    </div>
                </vuestic-widget>
            </div>
            <div class="col-md-12">
                <TableTypeStation></TableTypeStation>
            
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import TableTypeStation from '../tables/TableTypeStation/TableTypeStation.vue'
    import {SpringSpinner} from 'epic-spinners'
    export default {
        name:       'Table',
        components: {
            SpringSpinner,
            TableTypeStation
        },
        data() {
            return {}
        },
        methods: {
            newTypestation () {
                this.$router.push('station/new')
            }

        }
    }
</script>

<style lang="scss">
    
    .color-icon-label-table {
        td:first-child {
            width: 1rem;
        }
    }
</style>
